<template>
  <div>
    <b-navbar type="dark" variant="dark" fixed="top">
      <b-container
        class="d-flex"
        style="margin: 0; padding: 0; max-width: 100%"
      >
        <b-navbar-nav class="justify-content-end">
          <b-button
            id="menubtn"
            v-b-toggle.sidebar-backdrop
            variant="dark"
            class="mr-2 border"
          >
            <b-icon icon="list" aria-hidden="true" dark></b-icon>
          </b-button>

        <div class="d-flex align-items-center">
          <img :src="iconUrl" alt="icon" title="icon" width="40" />
        <div v-if="speechBubbleUrl">
          <img :src="speechBubbleUrl" alt="speechBubble" title="speechBubble" width="80" height="40"/>
        </div>
        </div>

        </b-navbar-nav>

        <b-navbar-nav>
          <b-dropdown
            class="d-flex justify-content-end"
            block
            variant="dark"
            v-bind:text="authInfo.userName"
          >
            <div class="text">
              <b-dropdown-item href="/auth/passwordChange"
                ><b-icon icon="gear-fill" aria-hidden="true"></b-icon>
                パスワードの変更
              </b-dropdown-item>
              <b-dropdown-item href="#" v-on:click="logout"
                ><b-icon icon="door-open" aria-hidden="true"></b-icon>
                ログアウト</b-dropdown-item
              >
            </div>
          </b-dropdown>
        </b-navbar-nav>
      </b-container>
    </b-navbar>
  </div>
</template>

<script>
import api from "../../services/api";

export default {
  data() {
    return {};
  },
  computed: {
    authInfo() {
      return this.$store.state.authInfo;
    },
    iconUrl(){
      console.log("iconUrl", process.env.VUE_APP_ICON)
      return process.env.VUE_APP_ICON
    },
    speechBubbleUrl(){
      console.log("speechBubbleUrl", process.env.VUE_APP_SPEECHBUBBLE)
      return process.env.VUE_APP_SPEECHBUBBLE
    }
  },
  methods: {
    // ログアウト処理
    logout() {
      // cognito
      this.$cognito.logout();

      // api側ログアウト
      const params = {
        userId: this.$store.state.authInfo.userId,
        userName: this.$store.state.authInfo.userName,
      };
      api.logout(params);

      // セッション削除
      localStorage.clear();

      // 画面遷移
      this.$router.replace("/auth/login");
    },
  },
};
</script>

<style scoped>
.text {
  background-color: #ccc;
}
</style>
