<template>
  <div class="container">
    <div class="mt-5">
      <b-form-group
        label="ユーザー検索"
        label-cols-sm="3"
        label-align-sm="right"
        label-size="lg"
      >
      </b-form-group>
    </div>

    <b-container fluid>
      <b-row class="userName">
        <b-col sm="2">
          <label for="user-name">ユーザー名 : </label>
        </b-col>
        <b-col sm="3">
          <div role="group">
            <b-form-input
              id="input-live"
              type="search"
              v-model="userName"
              :state="userNameState"
              aria-describedby="input-live-help input-live-feedback"
              trim
              @keydown.enter="onUserSearch"
            ></b-form-input>
            <b-form-invalid-feedback id="input-live-feedback">
              50文字以内で入力してください
            </b-form-invalid-feedback>
          </div>
        </b-col>
      </b-row>

      <b-row class="companyName">
        <b-col sm="2">
          <label for="company-name">会社名 : </label>
        </b-col>
        <b-col sm="3">
          <div role="group">
            <b-form-input
              id="input-live"
              type="search"
              v-model="companyName"
              :state="companyNameState"
              aria-describedby="input-live-help input-live-feedback"
              trim
              @keydown.enter="onUserSearch"
            ></b-form-input>
            <b-form-invalid-feedback id="input-live-feedback">
              50文字以内で入力してください
            </b-form-invalid-feedback>
          </div>
        </b-col>
      </b-row>

      <b-row class="departmentName">
        <b-col sm="2">
          <label for="department-name">部署名 : </label>
        </b-col>
        <b-col sm="3">
          <div role="group">
            <b-form-input
              id="input-live"
              type="search"
              v-model="departmentName"
              :state="departmentNameState"
              aria-describedby="input-live-help input-live-feedback"
              trim
              @keydown.enter="onUserSearch"
            ></b-form-input>
            <b-form-invalid-feedback id="input-live-feedback">
              50文字以内で入力してください
            </b-form-invalid-feedback>
          </div>
        </b-col>
      </b-row>

      <b-row class="invalidUserCheckBox">
        <b-col lg="2">
          <label for="user-name">無効中のユーザも含む : </label>
        </b-col>
        <b-col sm="3" size="lg">
          <b-form-checkbox
            size="lg"
            v-model="status"
            value="1"
            unchecked-value="0"
          ></b-form-checkbox>
        </b-col>
      </b-row>

      <b-row>
        <b-col sm="1" align-self="end">
          <b-button @click="onUserSearch" class="searchButton" size="sm">
            <b-icon icon="Search" font-scale="1"></b-icon>
          </b-button>
        </b-col>
      </b-row>
    </b-container>

    <b-button class="createNewButton" to="/admin/user/new" size="sm"
      >新規登録</b-button
    >

    <b-table striped hover :items="users" :fields="fields">
      <template v-slot:cell(userName)="data">
        <a :href="`/admin/user/edit/${users[data.index].userId}`">{{
          data.value
        }}</a>
      </template>
    </b-table>
  </div>
</template>

<script>
import api from "../../../services/api";

export default {
  data() {
    return {
      users: null,
      userName: "",
      companyName: "",
      departmentName: "",
      invalidFlag: "",
      status: "0",
      roleEnum: {
        user: "一般",
        admin: "管理者",
      },
      invalidEnum: {
        0: "有効",
        1: "無効",
      },
      fields: [
        { key: "userName", label: "ユーザー名" },
        { key: "companyName", label: "会社名" },
        { key: "departmentName", label: "部署名" },
        { key: "mailaddress", label: "メールアドレス" },
        { key: "roleName", label: "権限" },
        { key: "invalidName", label: "状態" },
      ],
    };
  },

  computed: {
    // ユーザー名が51文字以上入力されたときに、アラートを出すためにfalseを返す
    userNameState() {
      return this.userName.length <= 50 ? null : false;
    },

    // 会社名が51文字以上入力されたときに、アラートを出すためにfalseを返す
    companyNameState() {
      return this.companyName.length <= 50 ? null : false;
    },

    // 部署名が51文字以上入力されたときに、アラートを出すためにfalseを返す
    departmentNameState() {
      return this.departmentName.length <= 50 ? null : false;
    },
  },

  methods: {
    // ユーザー名称検索
    onUserSearch() {
      if (
        this.userNameState == null &&
        this.companyNameState == null &&
        this.departmentNameState == null
      ) {
        if (this.status == "0") {
          this.invalidFlag = "";
        } else {
          this.invalidFlag = this.status;
        }
        // API呼び出し
        api
          .getUsers(
            this.userName,
            this.companyName,
            this.departmentName,
            this.invalidFlag
          )
          .then((result) => {
            this.users = result.data.result;
            this.users.map((item, index) => {
              this.users[index].roleName = this.roleEnum[item.role];
              this.users[index].invalidName =
                this.invalidEnum[item.invalidFlag];
            });
          })
          .catch((err) => {
            // エラーログを出すこと
            console.log(err);
          });
      } else {
        // ここに入った場合、何もおきない
      }
    },
  },

  created() {},

  mounted() {
    this.onUserSearch();
  },
};
</script>

<style scoped>
.userName {
  margin-top: 10px;
}

.companyName {
  margin-top: 10px;
}

.departmentName {
  margin-top: 10px;
}

.invalidUserCheckBox {
  margin-top: 10px;
}

.searchButton {
  margin-left: 650px;
}

.createNewButton {
  margin: 30px;
}

.userStatus {
  margin: 10px 130px 0px 130px;
}
</style>
