<template>
  <div class="logSearch">
    <label for="startDateDatepicker">開始日</label>
    <b-col sm="6">
      <b-form-datepicker
        id="startDateDatepicker"
        v-model="startDate"
      ></b-form-datepicker>
    </b-col>

    <label for="endDateDatepicker">終了日</label>
    <b-col sm="6">
      <b-form-datepicker
        id="endDateDatepicker"
        aria-describedby="inputLiveHelp inputLiveFeedback"
        v-model="endDate"
        :state="endDateState"
      ></b-form-datepicker>
      <b-form-invalid-feedback id="endDateDatepicker">
        終了日は開始日以降の日付を設定してください。<br />
      </b-form-invalid-feedback>
    </b-col>

    <b-row class="userName">
      <b-col sm="2">
        <label for="user-name">ユーザー名 : </label>
      </b-col>
      <b-col sm="3">
        <div role="group">
          <b-form-input
            id="inputLive"
            type="search"
            v-model="userName"
            :state="userNameState"
            aria-describedby="inputLiveHelp inputLiveFeedback"
            trim
          ></b-form-input>
          <b-form-invalid-feedback id="inputLiveFeedback">
            50文字以内で入力してください
          </b-form-invalid-feedback>
        </div>
      </b-col>
    </b-row>

    <b-row class="companyName">
      <b-col sm="2">
        <label for="company-name">会社名 : </label>
      </b-col>
      <b-col sm="3">
        <div role="group">
          <b-form-input
            id="inputLive"
            type="search"
            v-model="companyName"
            :state="companyNameState"
            aria-describedby="inputLiveHelp inputLiveFeedback"
            trim
          ></b-form-input>
          <b-form-invalid-feedback id="inputLiveFeedback">
            50文字以内で入力してください
          </b-form-invalid-feedback>
        </div>
      </b-col>
    </b-row>

    <b-row class="departmentName">
      <b-col sm="2">
        <label for="department-name">部署名 : </label>
      </b-col>
      <b-col sm="3">
        <div role="group">
          <b-form-input
            id="inputLive"
            type="search"
            v-model="departmentName"
            :state="departmentNameState"
            aria-describedby="inputLiveHelp inputLiveFeedback"
            trim
          ></b-form-input>
          <b-form-invalid-feedback id="inputLiveFeedback">
            50文字以内で入力してください
          </b-form-invalid-feedback>
        </div>
      </b-col>
    </b-row>

    <div>
      <b-button class="button" size="sm" v-on:click="download"
        >ログ出力</b-button
      ><br />
    </div>
  </div>
</template>

<script>
import api from "../../services/api";
import moment from "moment";

export default {
  methods: {
    createParams() {
      this.params.startDate = this.startDate;
      this.params.endDate = this.endDate;
      this.params.userName = this.userName;
      this.params.companyName = this.companyName;
      this.params.departmentName = this.departmentName;
      return this.params;
      // return JSON.stringify(this.params);
    },

    // download
    download(event) {
      event.preventDefault();

      // 開始日と終了日の期間
      const checkDateDiff = moment(this.endDate).diff(
        moment(this.startDate),
        "days"
      );

      // 開始日、終了日の入力、検索日数バリデーション
      if (this.startDate == "" || this.endDate == "") {
        alert("開始日、終了日の入力は必須です。");
        return;
      } else if (checkDateDiff > 7) {
        alert("ログの検索日数は1週間以内にしてください。");
        return;
      }

      //入力値が意図しない場合はapiを叩かない。
      if (
        this.userNameState == false ||
        this.companyNameState == false ||
        this.departmentNameState == false ||
        this.startDate == "" ||
        this.endDate == "" ||
        this.endDateState == false
      ) {
        // ここに入った場合、何もおきない
        return;
      } else {
        // ダウンロード処理
        api
          .downloadLogs(this.createParams())
          .then((response) => {
            // ダウンロードファイル名
            const contentDisposition = response.headers["content-disposition"];
            const fileName = contentDisposition.replace(
              "attachment; filename=",
              ""
            );

            // 取得したデータをブラウザでダウンロード
            const url = URL.createObjectURL(
              new Blob([response.data], { type: "text/csv" })
            );
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", fileName);
            document.body.appendChild(link);
            link.click();
            URL.revokeObjectURL(url);
            link.parentNode.removeChild(link);
          })
          .catch((error) => {
            // エラートーストを出す
            alert(JSON.stringify(error));
          });
      }
    },
  },

  data() {
    return {
      startDate: "",
      endDate: "",
      userName: "",
      companyName: "",
      departmentName: "",
      params: {
        startDate: "",
        endDate: "",
        userName: "",
        companyName: "",
        departmentName: "",
      },
    };
  },

  computed: {
    //ユーザー名の文字数確認
    userNameState() {
      return this.userName.length <= 50 ? null : false;
    },

    // 会社名の文字数確認
    companyNameState() {
      return this.companyName.length <= 50 ? null : false;
    },

    // 部署名の文字数確認
    departmentNameState() {
      return this.departmentName.length <= 50 ? null : false;
    },

    // 開始日が終了日より未来でないかを確認
    endDateState() {
      if (this.startDate && this.endDate) {
        return new Date(this.endDate) - new Date(this.startDate) >= 0
          ? null
          : false;
      } else {
        return null;
      }
    },
  },
};
</script>

<style scoped>
.logSearch {
  margin: 10px 130px 0px 130px;
}

.button {
  margin: 10px 0px 20px 0px;
}
.userName {
  margin-top: 20px;
}

.companyName {
  margin-top: 20px;
}

.departmentName {
  margin-top: 20px;
}
</style>