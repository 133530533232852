<template>
  <div class="about">
    <Header />
    <Sidebar />
    <br><br><br>
    <user/>
  </div>
</template>

<script>
import Header from "@/components/general/header";
import Sidebar from "@/components/general/sidebar";
import user from "@/components/admin/user/view.vue";

export default {
  name: 'about',
  components: {
    user,
    Header,
    Sidebar
  },
}

</script>