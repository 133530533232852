<template>
  <div class="passwordReset">
    <passwordReset />
  </div>
</template>

<script>
// @ is an alias to /src
import passwordReset from '@/components/auth/passwordReset.vue'

export default {
  components: {
    passwordReset
  }
}
</script>

<style>
.passwordReset {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  padding: 30px;
}
</style>