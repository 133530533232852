<template>
  <div>
    <b-button
      variant="primary"
      @click="modalShow1 = !modalShow1, clickButton()" 
      :disabled="isDisabled"
    >{{ buttonTitle }}</b-button>

    <b-modal v-model="modalShow1" :title="operationTitle">
      {{ operationComment }}
      <template #modal-footer>
        <b-button @click="response(true)">OK</b-button>
        <b-button @click="response(false)">Cancel</b-button>
      </template>
    </b-modal>

    <b-modal v-model="modalShow2" :title="acceptTitle" hide-footer>
      {{ acceptComment }}
      <template #modal-footer> </template>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: "modal1",
  props: {
    buttonTitle: {
      type: String,
      default: "",
    },
    operationTitle: {
      type: String,
      default: "",
    },
    operationComment: {
      type: String,
      default: "",
    },
    acceptTitle: {
      type: String,
      default: "",
    },
    acceptComment: {
      type: String,
      default: "",
    },
    isDisabled: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      modalShow1: false,
      modalShow2: false,
    };
  },
  methods: {
    response(result) {
      if (result) {
        // 承認
        this.modalShow1 = false;
        this.modalShow2 = true;
        this.$emit("response", { result: true, message: "承認しました" });
      } else {
        // 拒否
        this.modalShow1 = false;
        this.modalShow2 = false;
        this.$emit("response", { result: false, message: "拒否しました" });
      }
    },

    clickButton() {
      this.$emit("responseButtonTitle", this.buttonTitle);
    }

  },
};
</script>
