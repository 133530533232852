<template>
  <div class="about">
    <Header />
    <Sidebar />
    <br />
    <rebooterView />
  </div>
</template>

<script>
import rebooterView from "@/components/rebooter/view.vue";
import Header from "@/components/general/header";
import Sidebar from "@/components/general/sidebar";

export default {
  name: "about",
  components: {
    rebooterView,
    Header,
    Sidebar,
  },
};
</script>
