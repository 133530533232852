<template>
  <div class="passwordreset">
    <div class="col-12 mt-3">
      <h3>パスワードの再発行</h3>
    </div>

    <b-form @submit.prevent="sendVerificationCode">
      <div class="container" v-show="showFlag">
        <div class="row align-items-center">
          <div class="col-12 mt-3">メールアドレス</div>
          <div class="col-12 mt-1">
            <input
              type="email"
              placeholder="Mailaddress"
              maxlength="100"
              v-model="userName"
            />
          </div>
        </div>
        <div class="col-12 mt-3">
          <b-button class="password-reset cancel" v-on:click="onBack" variant="dark" type="button">キャンセル</b-button>
          <b-button class="password-reset" variant="primary" type="submit">送信</b-button>
        </div>
      </div>
    </b-form>

    <b-form @submit.prevent="passwordReset">
      <div class="container" v-show="!showFlag">
        <div class="row align-items-center">
          <div class="col-12 mt-3">新しいパスワード</div>
          <div class="col-12 mt-1">
            <input
              type="password"
              placeholder="新しいパスワード"
              pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!#$%()*+,-./:;<=>?@^_`{|}~\[\]]).{8,}"
              title="8文字以上、小文字、大文字、数字、特殊記号を1文字以上含む。"
              required
              maxlength="100"
              v-model="newPassword"
            />
          </div>
        </div>

        <div class="row align-items-center">
          <div class="col-12 mt-3">新しいパスワード(確認用)</div>
          <div class="col-12 mt-1">
            <input
              type="password"
              placeholder="新しいパスワード(確認用)"
              pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!#$%()*+,-./:;<=>?@^_`{|}~\[\]]).{8,}"
              title="8文字以上、小文字、大文字、数字、特殊記号を1文字以上含む。"
              required
              maxlength="100"
              v-model="confirmationNewPassword"
            />
          </div>
        </div>

        <div class="row align-items-center">
          <div class="col-12 mt-3">認証コード</div>
          <div class="col-12 mt-1">
            <input
              type="tel"
              placeholder="認証コード"
              pattern="(?=.*\d).{6,6}"
              maxlength="100"
              title="6桁の半角数字を入力してください"
              required
              v-model="verificationCode"
            />
          </div>
        </div>

        <div class="col-12 mt-3">
          <b-button variant="primary" type="submit">変更</b-button>
        </div>
      </div>
    </b-form>
  </div>
</template>

<script>
import message from "../../message";
export default {
  data() {
    return {
      userName: "",
      newPassword: "",
      confirmationNewPassword: "",
      verificationCode: "",
      showFlag: true,
    };
  },

  methods: {
    sendVerificationCode: function () {
      this.$cognito
        .sendVerificationCode(this.userName)
        .then((result) => {
          console.log(`sendOk: `, result);
          this.showFlag = false;
          alert(message.I200);
        })
        .catch((err) => {
          // cognitoのエラーは一旦原文のままだす。
          alert(message.E400 + err);
        });
    },

    passwordReset: function () {
      if (this.confirmationNewPassword != this.newPassword) {
        alert(message.E202);
        return;
      } else {
        this.$cognito
          .confirmPassword(
            this.userName,
            this.newPassword,
            this.verificationCode
          )
          .then((result) => {
            console.log(`resetOk: `, result);
            alert(message.I201);
            window.location.href = "/auth/login";
          })
          .catch((err) => {
            //cognitoのエラーは一旦原文のままだす。
            alert(message.E400 + err);
          });
      }
    },

    // 戻る
    onBack() {
      this.$router.push("/auth/login");
    }

  },
};
</script>

<!--  Add "scoped" attribute to limit CSS to this component only -->
<style>
.btn.password-reset {
  width: 120px;
}
.btn.password-reset.cancel {
  margin-right: 10px;
}
</style>
<style scoped>
input {
  margin: 10px 0;
  padding: 10px;
}
</style>
