<template>
  <div class="passwordChange">
    <h3>パスワード変更</h3>
    <b-form @submit.prevent="passwordChange">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-12 mt-3">元のパスワード</div>
          <div class="col-12 mt-1">
            <input
              type="password"
              placeholder="元のパスワード"
              pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!#$%()*+,-./:;<=>?@^_`{|}~\[\]]).{8,}"
              title="8文字以上、小文字、大文字、数字、特殊記号を1文字以上含む。"
              maxlength="100"
              required
              v-model="nowPassword"
            />
          </div>
        </div>

        <div class="row align-items-center">
          <div class="col-12 mt-3">新しいパスワード</div>
          <div class="col-12 mt-1">
            <input
              type="password"
              placeholder="新しいパスワード"
              pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!#$%()*+,-./:;<=>?@^_`{|}~\[\]]).{8,}"
              title="8文字以上、小文字、大文字、数字、特殊記号を1文字以上含む。"
              maxlength="100"
              required
              v-model="newPassword"
            />
          </div>
        </div>

        <div class="row align-items-center">
          <div class="col-12 mt-3">新しいパスワード(確認用)</div>
          <div class="col-12 mt-1">
            <input
              type="password"
              placeholder="新しいパスワード(確認用)"
              pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!#$%()*+,-./:;<=>?@^_`{|}~\[\]]).{8,}"
              title="8文字以上、小文字、大文字、数字、特殊記号を1文字以上含む。"
              maxlength="100"
              required
              v-model="confirmationNewPassword"
            />
          </div>
        </div>
        <div class="col-12 mt-3">
          <div>
            <b-button class="password-change cancel" v-on:click="onBack" variant="dark" type="button">キャンセル</b-button>
            <b-button class="password-change" variant="primary" type="submit">変更</b-button>
          </div>
        </div>
      </div>
    </b-form>
  </div>
</template>

<script>
import api from "../../services/api";
import message from "../../message";
export default {
  data() {
    return {
      nowPassword: "",
      newPassword: "",
      confirmationNewPassword: "",
    };
  },
  methods: {
    passwordChange: function () {
      if (this.confirmationNewPassword != this.newPassword) {
        alert(message.E202);
        return;
      } else {
        this.$cognito
          .getCognitoSession()
          .then((result) => {
            // 登録パラメータ
            const params = {
              session: result,
              userName: String(result.accessToken.payload.username),
              oldPassword: this.nowPassword,
              newPassword: this.newPassword,
            };

            // パスワード変更処理
            api
              .putUserPasswordExpiredAt(params)
              .then((result) => {
                // 成功したらリブーター一覧画面へ戻る
                if (result.status === 200) {
                  // 一覧画面へ遷移
                  this.$router.push("/rebooter/view");
                } else {
                  // 失敗したらアラート出す
                  alert(JSON.stringify(result.data.result));
                }
              })
              .catch((err) => {
                // エラーログを出すこと
                console.log(err);
              });
          })
          .catch((err) => {
            //cognitoのエラーは一旦原文のままだす。
            alert(message.E400 + err);
          });
      }
    },

    // 戻る
    onBack() {
      this.$router.push("/rebooter/view");
    }

  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.btn.password-change {
  width: 120px;
}
.btn.password-change.cancel {
  margin-right: 10px;
}
</style>

<style scoped>
input {
  margin: 10px 0;
  padding: 10px;
}
</style>
