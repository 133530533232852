<template>
  <div class="about">
    <Header />
    <Sidebar />
    <br /><br /><br />
    <control />
  </div>
</template>

<script>
import Header from "@/components/general/header";
import Sidebar from "@/components/general/sidebar";
import control from "@/components/rebooter/control";

export default {
  name: "about",
  components: {
    control,
    Header,
    Sidebar,
  },
};
</script>
