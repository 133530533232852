<template>
  <div class="about">
    <Header />
    <Sidebar />
    <br><br><br>
    <log/>
  </div>
</template>

<script>
import Header from "@/components/general/header";
import Sidebar from "@/components/general/sidebar";
import log from "@/components/log/log.vue";

export default {
  name: 'about',
  components: {
    log,
    Header,
    Sidebar
  }
}

</script>