<template>
  <div class="about">
    <Header />
    <Sidebar />
    <br /><br /><br />
    <user :form="form" :mode="mode" :id="id" />
  </div>
</template>

<script>
import Header from "@/components/general/header";
import Sidebar from "@/components/general/sidebar";
import user from "@/components/admin/user/form.vue";
import api from "../../../services/api";

export default {
  name: "about",
  components: {
    user,
    Header,
    Sidebar,
  },
  data: () => {
    return {
      form: {
        userName: "",
        companyName: "",
        departmentName: "",
        mailaddress: "",
        role: "",
        invalidFlag: "",
      },
      mode: "edit",
      id: "",
    };
  },

  methods: {
    init() {
      const id = this.$route.params.id;
      this.id = id;

      // ユーザー名称検索
      api
        .getUser(id)
        .then((result) => {
          this.form.userName = result.data.result.userName;
          this.form.companyName = result.data.result.companyName;
          this.form.departmentName = result.data.result.departmentName;
          this.form.mailaddress = result.data.result.mailaddress;
          this.form.role = result.data.result.role;
          this.form.invalidFlag = result.data.result.invalidFlag;
        })
        .catch((err) => {
          // エラーログを出すこと
          console.log(err);
        });
    },
  },

  created() {},

  mounted() {
    this.init();
  },
};
</script>
