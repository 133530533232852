<template>
  <div class="about">
    <Header />
    <Sidebar />
    <br /><br /><br />
    <user :form="form" :mode="mode" />
  </div>
</template>

<script>
import Header from "@/components/general/header";
import Sidebar from "@/components/general/sidebar";
import user from "@/components/admin/user/form.vue";

export default {
  name: "about",
  components: {
    user,
    Header,
    Sidebar,
  },
  data: () => {
    return {
      form: {
        userName: "",
        companyName: "",
        departmentName: "",
        mailaddress: "",
        role: "",
        invalidFlag: ""
      },
      mode: "create",
    };
  },
};
</script>
