<template>
  <div class="about">
    <Header />
    <Sidebar />
    <br /><br /><br />
    <formRebooter :form="form" :mode="mode" :id="id" />
  </div>
</template>

<script>
import Header from "@/components/general/header";
import Sidebar from "@/components/general/sidebar";
import formRebooter from "@/components/rebooter/form.vue";
import api from "../../services/api";

export default {
  name: "about",
  components: {
    formRebooter,
    Header,
    Sidebar,
  },
  data: () => {
    return {
      form: {
        macAddress: "",
        ipAddress: "",
        sendMailAddress: "",
        manufacturer: "",
        shopName: "",
        outlet1Name: "",
        outlet2Name: "",
        outlet3Name: "",
        outlet4Name: "",
        controlId: "",
        controlPassword: "",
        remarks: "",
        exclusionFlag: "",
      },
      mode: "edit",
      id: "",
    };
  },

  methods: {
    init() {
      const id = this.$route.params.id;
      this.id = id;

      // リブーター検索
      api
        .getRebooter(id)
        .then((result) => {
          this.form.macAddress = result.data.rebooter.macAddress;
          this.form.ipAddress = result.data.rebooter.ipAddress;
          this.form.sendMailAddress = result.data.rebooter.sendMailaddress;
          this.form.manufacturer = result.data.rebooter.manufacturer;
          this.form.shopName = result.data.rebooter.shopName;
          this.form.outlet1Name = result.data.rebooter.outlet1Name;
          this.form.outlet2Name = result.data.rebooter.outlet2Name;
          this.form.outlet3Name = result.data.rebooter.outlet3Name;
          this.form.outlet4Name = result.data.rebooter.outlet4Name;
          this.form.controlId = result.data.rebooter.controlId;
          this.form.controlPassword = result.data.rebooter.controlPassword;
          this.form.remarks = result.data.rebooter.remarks
            ? result.data.rebooter.remarks
            : "";
          this.form.exclusionFlag = result.data.rebooter.exclusionFlag;
        })
        .catch((err) => {
          // エラーログを出すこと
          console.log(err);
        });
    },
  },

  created() {},

  mounted() {
    this.init();
  },
};
</script>
