<template>
  <div>
    <aside>
      <b-sidebar
        id="sidebar-backdrop"
        backdrop
        shadow
        no-header
        bg-variant="dark"
        sidebar-class="border-right"
        width="200px"
      >
        <b-list-group flush>
          <b-list-group-item
            to="/rebooter/view"
            variant="dark"
            active-class="active"
            exact
          >
            <b-icon icon="view-list" class="mr-2"></b-icon> 機器一覧
          </b-list-group-item>
          <b-list-group-item
            to="/rebooter/new"
            variant="dark"
            active-class="active"
            v-show="isShowChange"
          >
            <b-icon icon="plus-square" class="mr-2"></b-icon> 機器登録
          </b-list-group-item>
          <b-list-group-item
            to="/admin/user/view"
            variant="dark"
            active-class="active"
            v-show="isShowChange"
          >
            <b-icon icon="people-fill" class="mr-2"></b-icon> ユーザー管理
          </b-list-group-item>

          <b-list-group-item to="/log" variant="dark" active-class="active">
            <b-icon icon="file-arrow-down" class="mr-2"></b-icon> ログ出力
          </b-list-group-item>
        </b-list-group>
      </b-sidebar>
    </aside>
  </div>
</template>

<script>
import api from "../../services/api";

export default {
  data() {
    return {
      role: "",
      show: true,
    };
  },

  methods: {},

  mounted() {
    api
      .authorization()
      .then((result) => {
        this.role = result.data.result.role;
      })
      .catch((err) => {
        // エラーログを出すこと
        console.log(err);
      });
  },

  computed: {
    // 権限がuserの場合は、機器登録、ユーザー管理をを非表示にする
    isShowChange() {
      if (this.role == "admin") {
        return this.show == true;
      } else {
        return this.show == false;
      }
    },
  },
};
</script>

<style></style>
