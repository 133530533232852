<template>
  <!-- 検索エリア -->
  <div class="container">
    <div class="mt-5">
      <div class="title">
        <b-form-group
          label="リブーター検索"
          label-align-sm="right"
          label-size="lg"
        >
        </b-form-group>
      </div>
    </div>

    <b-container fluid>
      <b-row class="baseName">
        <b-col sm="3">
          <label for="base-name">拠点名 : </label>
        </b-col>
        <b-col sm="3">
          <b-form-input
            id="input-live"
            type="search"
            v-model="baseName"
            :state="baseNameState"
            aria-describedby="input-live-help input-live-feedback"
            trim
            @keydown.enter="onOutletSearch"
          ></b-form-input>
          <b-form-invalid-feedback id="input-live-feedback">
            80文字以内で入力してください
          </b-form-invalid-feedback>
        </b-col>
      </b-row>

      <b-row class="mt-3">
        <b-col sm="3">
          <label for="outlet-name">アウトレット名称 : </label>
        </b-col>
        <b-col sm="3">
          <b-form-input
            id="input-live"
            type="search"
            v-model="outletName"
            :state="outletNameState"
            aria-describedby="input-live-help input-live-feedback"
            trim
            @keydown.enter="onOutletSearch"
          ></b-form-input>
          <b-form-invalid-feedback id="input-live-feedback">
            50文字以内で入力してください
          </b-form-invalid-feedback>
        </b-col>
      </b-row>

      <b-row class="outletStatus mt-3">
        <b-row>
          <b-col sm="3">
            <label for="outlet-status">アウトレットの状態 : </label>
          </b-col>
          <b-col sm="1">
            <b-form-select
              id="input-3"
              v-model="form.outletStatus"
              :options="outletStatus"
            ></b-form-select>
          </b-col>
        </b-row>
        <b-row
          ><b-col sm="6"
            >※指定した状態が1つでも該当するリブーターを表示します。</b-col
          ></b-row
        >
      </b-row>

      <b-row class="mt-3">
        <b-col sm="3">
          <label for="user-name">使用停止中のリブーターも含む : </label>
        </b-col>
        <b-col sm="1">
          <b-form-checkbox
            v-model="status"
            value="1"
            unchecked-value="0"
          ></b-form-checkbox>
        </b-col>
      </b-row>

      <b-row class="mt-3">
        <b-col sm="3">
          <label for="limit">表示件数 : </label>
        </b-col>
        <b-col sm="1">
          <b-form-select
            id="input-3"
            v-model="form.limit"
            :options="limit"
          ></b-form-select>
        </b-col>
      </b-row>

      <b-row class="mt-3">
        <div class="offset-5">
          <b-button @click="onOutletSearch('1')" size="sm" class="primary">
            <b-icon icon="Search" font-scale="1"></b-icon>
          </b-button>
        </div>
      </b-row>
    </b-container>

    <!-- 一括操作用エリア -->
    <b-container fluid>
      <b-row class="mt-3">
        <b-col sm="6">
          <div style="border: 1px solid #333;padding: 5px;">
            <label>【一括操作】選択されているリブーターに対して</label>
            &nbsp;
            <b-button
              variant="primary"
              @click="bulkOperationModalShow('on')"
              style="width: 80px;"
            >
              ON
            </b-button>
            &nbsp;
            <b-button
              variant="primary"
              @click="bulkOperationModalShow('off')"
              style="width: 80px;"
            >
              OFF
            </b-button>
          </div>
        </b-col>
        <b-col sm="3">
          <div style="display: flex;margin-top: 8px;">
            <label for="limit">現在選択されている機器 : </label>
            <p style="width: 50px;text-align: right;">
              <span>
                {{ checkCount }}
              </span>
            </p>
          </div>
        </b-col>
      </b-row>
    </b-container>

    <!-- 機器追加ボタン -->
    <b-col sm="20" class="mb-3">
      <b-button
        size=""
        to="/rebooter/new"
        variant=""
        class="addRebooterButton"
        v-show="isShowChange"
        >機器追加</b-button
      >
    </b-col>

    <!-- ページング -->
    <div v-if="perPage != -1">
      <b-pagination
        v-model="currentPage"
        :total-rows="rows"
        :per-page="perPage"
        aria-controls="rebooters-table"
        @change="pageChange"
      ></b-pagination>
      <p class="mt-3">{{ currentPage }} ページ目</p>
    </div>

    <!-- 一覧再取得ボタン -->
    <b-button class="reload" size="" variant="dark" v-on:click="reload">
      <b-icon
        icon="arrow-clockwise"
        :animation="reloadAnimation"
        font-scale="1"
      ></b-icon>
    </b-button>

    <!-- リブーター一覧 -->
    <div>
      <b-table
        class="rebooters-table"
        id="rebooters-table"
        :items="items"
        :fields="fields"
        :per-page="perPage"
        :current-page="currentPage"
        striped
        :tbody-tr-class="rowClass"
        responsive="sm"
        label-sort-asc=""
        label-sort-desc=""
        label-sort-clear=""
      >
        <!-- チェック（ヘッダ行）-->
        <template v-slot:head(check)>
          <span @click="rowHeadClick">
            <b-icon
              v-if="checkBoxStatus === 0"
              icon="square"
              font-scale="1"
            ></b-icon>
            <b-icon
              v-if="checkBoxStatus === 1"
              icon="dash-square"
              font-scale="1"
            ></b-icon>
            <b-icon
              v-if="checkBoxStatus === 2"
              icon="check-square"
              font-scale="1"
            ></b-icon>
          </span>
        </template>

        <!-- チェック（データ行）-->
        <template v-slot:cell(check)="data">
          <span @click="rowClick(data.item, data.index)">
            <b-icon
              v-if="data.item.check === '0'"
              icon="square"
              font-scale="1"
            ></b-icon>
            <b-icon
              v-if="data.item.check === '1'"
              icon="check-square"
              font-scale="1"
            ></b-icon>
          </span>
        </template>

        <!-- 拠点 -->
        <template v-slot:cell(shopName)="data">
          <span class="d-inline-block shop-name">
            <a :href="`/rebooter/control/${data.item.rebooterId}`">{{
              data.value
            }}</a>
          </span>
        </template>

        <!-- アウトレット1 -->
        <template v-slot:cell(outlet1Name)="data">
          <span class="d-inline-block outlet-name">
            {{ data.value }}
          </span>
        </template>
        <template v-slot:cell(outlet1Status)="data">
          <span
            class="d-inline-block outlet-status"
            v-bind:class="data.item.status1Class"
          >
            {{ data.value }}
          </span>
        </template>

        <!-- アウトレット2 -->
        <template v-slot:cell(outlet2Name)="data">
          <span class="d-inline-block outlet-name">
            {{ data.value }}
          </span>
        </template>
        <template v-slot:cell(outlet2Status)="data">
          <span
            class="d-inline-block outlet-status"
            v-bind:class="data.item.status2Class"
          >
            {{ data.value }}
          </span>
        </template>

        <!-- アウトレット3 -->
        <template v-slot:cell(outlet3Name)="data">
          <span class="d-inline-block outlet-name">
            {{ data.value }}
          </span>
        </template>
        <template v-slot:cell(outlet3Status)="data">
          <span
            class="d-inline-block outlet-status"
            v-bind:class="data.item.status3Class"
          >
            {{ data.value }}
          </span>
        </template>

        <!-- アウトレット4 -->
        <template v-slot:cell(outlet4Name)="data">
          <span class="d-inline-block outlet-name">
            {{ data.value }}
          </span>
        </template>
        <template v-slot:cell(outlet4Status)="data">
          <span
            class="d-inline-block outlet-status"
            v-bind:class="data.item.status4Class"
          >
            {{ data.value }}
          </span>
        </template>

        <!-- ヘッダー用カラム -->
        <template #thead-top>
          <b-tr>
            <b-th colspan="1" class="check"><span class="sr-only"></span></b-th>
            <b-th colspan="1" class="outletBorder"
              ><span class="sr-only"></span
            ></b-th>
            <b-th colspan="2" class="outletBorder">アウトレット1</b-th>
            <b-th colspan="2" class="outletBorder">アウトレット2</b-th>
            <b-th colspan="2" class="outletBorder">アウトレット3</b-th>
            <b-th colspan="2" class="outletBorder">アウトレット4</b-th>
            <b-th><span class="updated sr-only"></span></b-th>
          </b-tr>
        </template>
      </b-table>
    </div>
    <b-modal
      v-model="bulkOperation.modalShow"
      :title="'リブーターの電源一括操作'"
      size="lg"
    >
      <div class="bulk_operation_modal">
        <div v-if="bulkOperation.stage === 0">
          <div class="header">
            <p>
              下記リブーター<span>{{ checkCount }}台</span
              >の全アウトレットを<span>{{
                bulkOperation.mode.toUpperCase()
              }}</span
              >にします、よろしいですか？
            </p>
          </div>
          <div class="content">
            <div class="list">
              <p v-for="item in checkItems" :key="item.rebooterId">
                {{ item.shopName }}
              </p>
            </div>
          </div>
        </div>
        <div v-if="bulkOperation.stage === 1">
          <div class="content">
            <b-icon
              icon="arrow-clockwise"
              :animation="bulkOperation.reloadAnimation"
              font-scale="5"
            ></b-icon>
          </div>
        </div>
        <div v-if="bulkOperation.stage === 2">
          <div class="content">
            <div>
              <p>操作を受け付けました。</p>
              <p>処理No. {{ bulkOperation.id }}</p>
            </div>
          </div>
        </div>
        <div v-if="bulkOperation.stage === 8">
          <div class="content">
            <div>
              <p>一括操作するリブーターが選択されていません。</p>
            </div>
          </div>
        </div>
        <div v-if="bulkOperation.stage === 9">
          <div class="content">
            <div>
              <p>操作を中断しました。</p>
            </div>
          </div>
        </div>
      </div>
      <template #modal-footer>
        <b-button @click="bulkOperationExec" variant="primary">OK</b-button>
        <b-button
          @click="bulkOperation.modalShow = !bulkOperation.modalShow"
          v-if="
            bulkOperation.stage !== 2 &&
              bulkOperation.stage !== 8 &&
              bulkOperation.stage !== 9
          "
          >Cancel</b-button
        >
      </template>
    </b-modal>
  </div>
</template>

<script>
import api from "../../services/api";
import moment from "moment";

//moment = require('moment-timezone');

export default {
  // =======================================
  // データ定義
  // =======================================
  data() {
    return {
      // 認証情報のユーザー権限
      role: "",

      // リブーター検索の拠点名
      baseName: "",
      // リブーター検索のアウトレット名
      outletName: "",
      // リブーター検索の使用停止中フラグ
      exclusionFlag: "",
      status: "0",
      // リブーター検索のアウトレット状態の設定値
      form: {
        // リブーター検索のアウトレット状態の設定値
        outletStatus: "",
        // 表示件数
        limit: 100,
      },
      // リブーター検索のアウトレットの状態のリスト
      outletStatus: [
        { text: "指定なし", value: "" },
        { text: "ON", value: "on" },
        { text: "OFF", value: "off" },
        { text: "不明", value: "unknown" },
      ],
      // 表示件数のリスト
      limit: [
        { text: " 100件", value: 100 },
        { text: "すべて", value: -1 },
      ],

      // 機器追加ボタン表示フラグ
      show: true,

      // 一覧のページング
      perPage: 100,
      currentPage: 1,

      // リブーター一覧のカラム
      fields: [
        {
          key: "check",
          label: "",
          thClass: "check",
          tdClass: "check",
          stickyColumn: false,
        },
        {
          key: "shopName",
          label: "拠点",
          thClass: "borderLeft",
          tdClass: "borderLeft",
          //sortable: false,
          stickyColumn: false,
        },
        {
          key: "outlet1Name",
          label: "名称",
          thClass: "borderLeft",
          tdClass: "borderLeft",
          //sortable: false,
        },
        {
          key: "outlet1Status",
          label: "状態",
          thClass: "borderRight",
          tdClass: "borderRight",
          //sortable: true,
        },
        {
          key: "outlet2Name",
          label: "名称",
          thClass: "borderLeft",
          tdClass: "borderLeft",
          //sortable: false,
        },
        {
          key: "outlet2Status",
          label: "状態",
          thClass: "borderRight",
          tdClass: "borderRight",
          //sortable: true,
        },
        {
          key: "outlet3Name",
          label: "名称",
          thClass: "borderLeft",
          tdClass: "borderLeft",
          //sortable: false,
        },
        {
          key: "outlet3Status",
          label: "状態",
          thClass: "borderRight",
          tdClass: "borderRight",
          //sortable: true,
        },
        {
          key: "outlet4Name",
          label: "名称",
          thClass: "borderLeft",
          tdClass: "borderLeft",
          //sortable: false,
        },
        {
          key: "outlet4Status",
          label: "状態",
          thClass: "borderRight",
          tdClass: "borderRight",
          //sortable: true,
        },
        /*
        {
          key: "rebooterStatus",
          label: "操作",
          thClass: "borderRight",tdClass: "borderRight",
        },
        */
        {
          key: "rebootersStatus.updatedAt",
          label: "取得日時",
          tdClass: "updated",
          //sortable: false,
        },
      ],

      // リブーター一覧データ
      items: [],

      // 更新ボタン
      reloadAnimation: "",

      // 表示行全て選択
      isAllSelected: "0",

      // ヘッダテーブルのチェックステータス(0: 未チェック、1:部分チェック、2:全てチェック)
      checkBoxStatus: 0,

      // 一括操作用
      bulkOperation: {
        // 一括操作ボタンの非活性
        btnDisabled: true,
        // モーダル表示フラグ
        modalShow: false,
        // 一括操作モード
        mode: "",
        // 一括操作処理ステージ
        stage: 0,
        // リロードアイコン制御用
        reloadAnimation: "",
        // 一括操作バッチID
        id: "",
      },

      // リブーターチェック件数
      checkCount: 0,
      // リブーターのチェックしたリスト
      checkItems: [],
    };
  },

  // =======================================
  // 値変更後に連動する処理
  // =======================================
  computed: {
    // リブーター一覧のデータ数
    rows() {
      return this.items.length;
    },

    // 拠点名が80文字以上入力されたときに、アラートを出すためにfalseを返す
    baseNameState() {
      return this.baseName.length <= 80 ? null : false;
    },

    // アウトレット名称が50文字以上入力されたときに、アラートを出すためにfalseを返す
    outletNameState() {
      return this.outletName.length <= 50 ? null : false;
    },

    // 権限がuserの場合は、機器登録ボタンを非表示にする
    isShowChange() {
      if (this.role == "admin") {
        return this.show == true;
      } else {
        return this.show == false;
      }
    },
  },

  // =======================================
  // イベント処理
  // =======================================
  methods: {
    // ---------
    // 再取得
    // ---------
    reload() {
      this.reloadAnimation = "spin";
      this.onOutletSearch();
      setTimeout(() => {
        this.reloadAnimation = "";
      }, 1000);
    },

    // ---------
    // アウトレット名称検索
    // ---------
    async onOutletSearch(mode) {
      const _this = this;

      if (this.baseNameState == null && this.outletNameState == null) {
        // 利用停止中チェック
        if (this.status == "0") {
          this.exclusionFlag = "";
        } else {
          this.exclusionFlag = String(this.status);
        }

        // 表示件数をページに設定
        if (mode !== undefined) {
          this.perPage = this.form.limit;
        }

        // API呼び出し
        await api
          .getRebooters(
            this.baseName,
            this.outletName,
            this.form.outletStatus,
            this.exclusionFlag
          )
          .then((result) => {
            //検索対象結果が0件の場合は一覧を空白にする。
            if (result.data.rebooters.length == 0) {
              this.items = result.data.rebooters;
            }
            for (let count = 0; count < result.data.rebooters.length; count++) {
              result.data.rebooters[count].rebootersStatus.updatedAt = moment
                .utc(result.data.rebooters[count].rebootersStatus.updatedAt)
                .add(9, "hours")
                .format("YYYY-MM-DD HH:mm:ss");

              // 描画制御
              switch (
                result.data.rebooters[count].rebootersStatus.rebooterStatus
              ) {
                // 非疎通
                case "disconnect":
                  if (result.data.rebooters[count].outletCount == 2) {
                    // リブーターのアウトレット名称
                    result.data.rebooters[count].outlet3Name = "";
                    result.data.rebooters[count].outlet4Name = "";

                    // リブーターの状態
                    result.data.rebooters[count].rebootersStatus.outlet1Status =
                      "不明";
                    result.data.rebooters[count].rebootersStatus.outlet2Status =
                      "不明";
                    result.data.rebooters[count].rebootersStatus.outlet3Status =
                      "なし";
                    result.data.rebooters[count].rebootersStatus.outlet4Status =
                      "なし";

                    this.items = result.data.rebooters;
                  } else if (result.data.rebooters[count].outletCount == 4) {
                    // リブーターの状態
                    result.data.rebooters[count].rebootersStatus.outlet1Status =
                      "不明";
                    result.data.rebooters[count].rebootersStatus.outlet2Status =
                      "不明";
                    result.data.rebooters[count].rebootersStatus.outlet3Status =
                      "不明";
                    result.data.rebooters[count].rebootersStatus.outlet4Status =
                      "不明";
                  }
                  break;

                // 稼働中
                case "active":
                  if (result.data.rebooters[count].outletCount == 2) {
                    // リブーターのアウトレット名称
                    result.data.rebooters[count].outlet3Name = "";
                    result.data.rebooters[count].outlet4Name = "";

                    // リブーターの状態
                    result.data.rebooters[count].rebootersStatus.outlet3Status =
                      "なし";
                    result.data.rebooters[count].rebootersStatus.outlet4Status =
                      "なし";
                  }
                  break;

                // 操作中
                case "inprogress":
                  if (result.data.rebooters[count].outletCount == 2) {
                    // リブーターのアウトレット名称
                    result.data.rebooters[count].outlet3Name = "";
                    result.data.rebooters[count].outlet4Name = "";

                    // リブーターの状態
                    result.data.rebooters[count].rebootersStatus.outlet3Status =
                      "なし";
                    result.data.rebooters[count].rebootersStatus.outlet4Status =
                      "なし";
                  }
                  break;

                // 使用停止中
                case "suspend":
                  if (result.data.rebooters[count].outletCount == 2) {
                    // リブーターのアウトレット名称
                    result.data.rebooters[count].outlet3Name = "";
                    result.data.rebooters[count].outlet4Name = "";

                    // リブーターの状態
                    result.data.rebooters[count].rebootersStatus.outlet1Status =
                      "停止";
                    result.data.rebooters[count].rebootersStatus.outlet2Status =
                      "停止";
                    result.data.rebooters[count].rebootersStatus.outlet3Status =
                      "なし";
                    result.data.rebooters[count].rebootersStatus.outlet4Status =
                      "なし";

                    this.items = result.data.rebooters;
                  } else if (result.data.rebooters[count].outletCount == 4) {
                    // リブーターの状態
                    result.data.rebooters[count].rebootersStatus.outlet1Status =
                      "停止";
                    result.data.rebooters[count].rebootersStatus.outlet2Status =
                      "停止";
                    result.data.rebooters[count].rebootersStatus.outlet3Status =
                      "停止";
                    result.data.rebooters[count].rebootersStatus.outlet4Status =
                      "停止";
                  }
                  break;
                default:
                  break;
              }
            }

            // ON, OFFを大文字に変換
            this.items = result.data.rebooters.map((item) => {
              // リブーターステータス
              item.rebooterStatus =
                item.rebootersStatus.rebooterStatus === "inprogress" ? "○" : "";

              // アウトレット状態
              item.status1Class =
                item.rebootersStatus.outlet1Status === "off" ? "error" : "";
              item.status2Class =
                item.rebootersStatus.outlet2Status === "off" ? "error" : "";
              item.status3Class =
                item.rebootersStatus.outlet3Status === "off" ? "error" : "";
              item.status4Class =
                item.rebootersStatus.outlet4Status === "off" ? "error" : "";
              item.outlet1Status = item.rebootersStatus.outlet1Status.toUpperCase();
              item.outlet2Status = item.rebootersStatus.outlet2Status.toUpperCase();
              item.outlet3Status = item.rebootersStatus.outlet3Status.toUpperCase();
              item.outlet4Status = item.rebootersStatus.outlet4Status.toUpperCase();

              // アウトレットステータスがどれかOFF
              item.sortKey1 = 99;
              if (
                item.outlet1Status === "OFF" ||
                item.outlet2Status === "OFF" ||
                item.outlet3Status === "OFF" ||
                item.outlet4Status === "OFF"
              ) {
                item.sortKey1 = 0;
              }

              // アウトレットステータスがどれか不明
              item.sortKey2 = 99;
              if (
                item.outlet1Status === "不明" ||
                item.outlet2Status === "不明" ||
                item.outlet3Status === "不明" ||
                item.outlet4Status === "不明"
              ) {
                item.sortKey2 = 0;
              }

              // アウトレットステータスが全てON
              item.sortKey3 = 99;
              if (item.outletCount === 2) {
                // アウトレット数が2個
                if (
                  item.outlet1Status === "ON" ||
                  item.outlet2Status === "ON"
                ) {
                  item.sortKey3 = 0;
                }
              }
              if (item.outletCount === 4) {
                // アウトレット数が4個
                if (
                  item.outlet1Status === "ON" ||
                  item.outlet2Status === "ON" ||
                  item.outlet3Status === "ON" ||
                  item.outlet4Status === "ON"
                ) {
                  item.sortKey3 = 0;
                }
              }

              // 使用停止
              item.sortKey4 = 99;
              if (item.rebootersStatus.rebooterStatus === "suspend") {
                item.sortKey4 = 0;
              }

              // 未チェック
              item.check = "0";

              // 返却
              return item;
            });

            // ソート
            this.items.sort((a, b) => {
              // アウトレット1～4まのどれかが"OFF"
              if (a.sortKey1 > b.sortKey1) {
                return 1;
              } else if (a.sortKey1 < b.sortKey1) {
                return -1;
              }

              // アウトレット1～4まのどれかが"不明"
              if (a.sortKey2 > b.sortKey2) {
                return 1;
              } else if (a.sortKey2 < b.sortKey2) {
                return -1;
              }

              // アウトレット1～4まの全てが"ON"
              if (a.sortKey3 > b.sortKey3) {
                return 1;
              } else if (a.sortKey3 < b.sortKey3) {
                return -1;
              }

              // 使用停止
              if (a.sortKey4 > b.sortKey4) {
                return 1;
              } else if (a.sortKey4 < b.sortKey4) {
                return -1;
              }

              // 拠点名の昇順
              if (a.shopName > b.shopName) {
                return 1;
              } else if (a.shopName < b.shopName) {
                return -1;
              }
              return 0;
            });
          })
          .catch((err) => {
            // エラーログを出すこと
            console.log(`getRebooters err: `, err);
          })
          .finally(() => {
            // ページ移動
            _this.currentPage = 1;
            // ヘッダチェックも解除
            _this.uncheck();
          });
      } else {
        // ここに入った場合、何もおきない
      }
    },

    // ---------
    // 行の状態に応じてclassを設定
    // ---------
    rowClass(item, type) {
      if (!item || type !== "row") {
        return;
      }

      const _outlet1Status = item.rebootersStatus.outlet1Status.toUpperCase();
      const _outlet2Status = item.rebootersStatus.outlet2Status.toUpperCase();
      const _outlet3Status = item.rebootersStatus.outlet3Status.toUpperCase();
      const _outlet4Status = item.rebootersStatus.outlet4Status.toUpperCase();

      if (
        _outlet1Status === "OFF" ||
        _outlet2Status === "OFF" ||
        _outlet3Status === "OFF" ||
        _outlet4Status === "OFF"
      ) {
        return "error";
      }
    },

    // ---------
    // ページ遷移イベント
    // ---------
    pageChange(page) {
      console.log(page);

      // チェック解除
      this.uncheck();
    },

    // チェック解除
    uncheck() {
      // チェック解除
      const _items = this.items.map((item) => {
        const _item = item;
        _item.check = "0";
        return _item;
      });
      this.items = _items;

      // ヘッダチェックも解除
      this.checkBoxStatus = 0;

      // チェック件数更新
      this.updateCheckCount();
    },

    // チェック件数を更新
    updateCheckCount() {
      const _checkCount = this.items.filter((item) => item.check === "1")
        .length;
      this.checkCount = _checkCount;
    },

    // ---------
    // テーブルヘッダチェック
    // ---------
    rowHeadClick() {
      console.log(`checkBoxStatus: ${this.checkBoxStatus}`);
      let _check = "0";

      const _this = this;

      // チェック状態の判定
      if (this.checkBoxStatus === 0) {
        // ========
        // 未チェックの場合、チェック済とする
        // ========
        // 全てチェック
        _check = "1";

        // ステータスをチェック状態へ切り替え
        this.checkBoxStatus = 2;
      } else if (this.checkBoxStatus === 1 || this.checkBoxStatus === 2) {
        // ========
        // 部分チェック、チェック済の場合、チェック済とする
        // ========
        // 未チェック
        _check = "0";

        // ステータスを未チェック状態へ切り替え
        this.checkBoxStatus = 0;
      }

      // 画面で設定した表示件数に応じてチェックする範囲を制御する
      const _items = this.items.map((item, index) => {
        const _item = item;

        // 表示件数でチェック範囲を制御
        if (_this.perPage === 100) {
          // 100件の場合は表示ページ範囲のみ
          const _from = (_this.currentPage - 1) * _this.perPage;
          const _to = _from + _this.perPage - 1;
          if (index >= _from && index <= _to) {
            _item.check = _check;
          }
        } else {
          // 全て
          _item.check = _check;
        }

        return _item;
      });
      this.items = _items;

      // チェック件数更新
      this.updateCheckCount();
    },

    // ---------
    // 行クリック時にチェックを切り替え、選択件数を更新
    // ---------
    rowClick(item, index) {
      console.log(`rowClick: `, item, index);

      const _this = this;

      // チェック切り替え
      const _paramItem = item;
      const _items = this.items.map((item) => {
        const _item = item;
        if (_item.rebooterId === _paramItem.rebooterId) {
          _item.check = _paramItem.check === "1" ? "0" : "1";
        }
        return _item;
      });
      this.items = _items;

      // ヘッダのチェックボックスステータス
      let _checkBoxStatus = 0;

      // チェック数を取得
      const _checkCount = this.items.filter((item) => item.check === "1")
        .length;

      // ページャーの単位でチェック数を調整
      if (_checkCount > 0) {
        if (_this.perPage === 100) {
          // 100件の場合は全てチェック扱い
          if (_checkCount >= 100) {
            // ステータス
            _checkBoxStatus = 2;
          } else {
            // ステータス
            _checkBoxStatus = 1;
          }
        } else {
          // 全ての場合
          if (_checkCount >= _this.items.length) {
            // ステータス
            _checkBoxStatus = 2;
          } else {
            // ステータス
            _checkBoxStatus = 1;
          }
        }
      } else {
        // 未チェック
        _checkBoxStatus = 0;
      }

      // ステータス設定
      _this.checkBoxStatus = _checkBoxStatus;

      // チェック件数更新
      this.updateCheckCount();
    },

    // ---------
    // 一括操作バッチモーダルを表示
    // ---------
    bulkOperationModalShow(mode) {
      // モード設定
      this.bulkOperation.mode = mode;
      // 処理ステージを初期化
      this.bulkOperation.stage = 0;

      // リブーターが選択されていない場合
      const _checkItems = this.items.filter((item) => item.check === "1");
      if (_checkItems.length <= 0) {
        this.bulkOperation.stage = 8;
      }
      this.checkItems = _checkItems;

      // モーダルを表示
      this.bulkOperation.modalShow = !this.bulkOperation.modalShow;
    },

    // ---------
    // 一括操作バッチ起動
    // ---------
    bulkOperationExec() {
      const _checkItems = this.items.filter((item) => item.check === "1");

      // APIを実行する
      if (this.bulkOperation.stage === 0) {
        // API実行
        api
          .postRebootersBulkOperation({
            mode: this.bulkOperation.mode,
            rebooter_ids: _checkItems.map((item) => item.rebooterId),
          })
          .then((result) => {
            console.log("result:", result);
            if (result.status === 200) {
              this.bulkOperation.id = result.data.bulk_operation_id;
            } else {
              // アクセストークン不備
              if (result.data.result.id === "E015") {
                alert(
                  `アクセストークンの有効期限が切れています、\n再ログインをお願いします。`
                );
                this.bulkOperation.stage = 9;
              }

              // バリデーション
              if (result.status === 400) {
                if (result.data.result.length > 0) {
                  alert(`パラメータ不備があります。`);
                  this.bulkOperation.stage = 9;
                }
              }

              // データ未存在
              if (result.status === 404) {
                alert(
                  `存在しないリブーターを指定している可能性があります。\n再読み込みしてください。`
                );
                this.bulkOperation.stage = 9;
              }

              // アプリエラー
              if (result.status === 500) {
                if (result.data.result.id === "E405") {
                  alert(`一括操作処理中です。`);
                  this.bulkOperation.stage = 9;
                }
                if (result.data.result.id === "E999") {
                  alert(`不正な例外を検知しました。`);
                  this.bulkOperation.stage = 9;
                }
              }
            }
          })
          .catch((err) => {
            // エラーログを出すこと
            console.log(`err: `, err);
            this.bulkOperation.stage = 9;
          })
          .finally(() => {
            if (this.bulkOperation.stage !== 9) {
              this.bulkOperation.stage = 2;
            }
          });

        // ローディングのステータスに切り替え
        this.bulkOperation.stage = 1;
        this.bulkOperation.reloadAnimation = "spin";
      }

      // API実行後の押下時は閉じる
      if (
        this.bulkOperation.stage === 2 ||
        this.bulkOperation.stage === 8 ||
        this.bulkOperation.stage === 9
      ) {
        this.bulkOperation.modalShow = !this.bulkOperation.modalShow;
      }
    },
  },

  // =======================================
  // マウント時処理
  // =======================================
  async mounted() {
    // リブーター一覧を取得
    await this.onOutletSearch();

    // 認証情報を取得し、権限を取得
    await api
      .authorization()
      .then((result) => {
        this.role = result.data.result.role;
      })
      .catch((err) => {
        // エラーログを出すこと
        console.log(`authorization err: `, err);
      })
      .finally(() => {});
  },
};
</script>

// 既存cssに統合用
<style>
.rebooters-table td .error {
  color: #ff0000;
  font-weight: bold;
}
.rebooters-table tr.error td {
  background-color: #f4d2c5;
}

.check {
  width: 50px;
  text-align: center;
}

.outletBorder {
  border-left: 1px solid black;
  border-right: 1px solid black;
}
.borderLeft {
  border-left: 1px solid black;
}
.borderRight {
  border-right: 1px solid black;
}
/* table td {
  border-left: 1px solid black;
  border-right: 1px solid black;
} */
table td span.shop-name {
  max-width: 200px;
  min-width: 200px;
  margin-right: 1px;
  overflow-wrap: break-word;
}
table td span.outlet-name {
  max-width: 120px;
  min-width: 120px;
  overflow-wrap: break-word;
}
table thead tr:nth-child(2) th:nth-child(3) div,
table thead tr:nth-child(2) th:nth-child(5) div,
table thead tr:nth-child(2) th:nth-child(7) div,
table thead tr:nth-child(2) th:nth-child(9) div {
  min-width: 40px;
  overflow-wrap: break-word;
}

table tbody tr td .outlet-status {
  white-space: nowrap;
}

table thead tr th .updated,
table tbody tr td.updated {
  max-width: 175px;
  min-width: 175px;
  overflow-wrap: break-word;
}
button.btn.reload {
  margin-right: 10px;
}
.reload:focus,
.reload:active:focus {
  box-shadow: none;
}
</style>

<style scoped>
.title {
  margin-top: 50px;
}

.baseName {
  margin-top: 10px;
}

.outletName {
  margin-top: 10px;
}

.outletStatus {
  margin-top: 10px;
}

.invalidRebooterCheckbox {
  margin-top: 10px;
}

.searchButton {
  margin-left: 650px;
}

.addRebooterButton {
  margin: 10px;
}

.bulk_operation_modal {
}
.bulk_operation_modal .header {
  margin-bottom: 10px;
}
.bulk_operation_modal .header p {
  color: #ff0000;
}
.bulk_operation_modal .header p span {
  font-weight: bold;
}
.bulk_operation_modal .content {
  padding: 5px;
}
.bulk_operation_modal .content .list {
  padding: 5px;
  overflow: auto;
  min-height: 100px;
  max-height: 300px;
}
.bulk_operation_modal .content p {
  margin-bottom: 5px;
}
</style>
