<template>
  <div class="passwordChange">
    <passwordChange />
  </div>
</template>

<script>
// @ is an alias to /src
import passwordChange from '@/components/auth/passwordChange.vue'

export default {
  components: {
    passwordChange
  }
}
</script>

<style>
.passwordChange {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  padding: 30px;
}
</style>