<template>
  <div class="about">
    <Header />
    <Sidebar />
    <br /><br /><br />
    <formRebooter :form="form" :mode="mode" />
    <h1></h1>
  </div>
</template>

<script>
import Header from "@/components/general/header";
import Sidebar from "@/components/general/sidebar";
import formRebooter from "@/components/rebooter/form.vue";

export default {
  name: "about",
  components: {
    formRebooter,
    Header,
    Sidebar,
  },
  data: () => {
    return {
      form: {
        macAddress: "",
        ipAddress: "",
        manufacturer: "",
        shopName: "",
        outlet1Name: "",
        outlet2Name: "",
        outlet3Name: "",
        outlet4Name: "",
        controlId: "",
        controlPassword: "",
        remarks: "",
        exclusionFlag: "",
      },
      mode: "create",
    };
  },
};
</script>
